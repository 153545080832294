<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Comisiones</h1>
            <div class="info-card">
                <p>Datos Generales</p>
                <hr style="color: var(--color-dark)">
                <div v-if="comm_data.loaded">
                    <div class="row">
                        <div class="col-sm-4"><p>Comisiones Generadas</p></div>
                        <div class="col-sm-8">
                            ${{formatMoney(comm_data.total_comm_money)[0]}},<label style="font-size: .8rem;">{{formatMoney(comm_data.total_comm_money)[1]}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4"><p>Comisiones Usuarios</p></div>
                        <div class="col-sm-8">
                            <label>${{formatMoney(comm_data.users_comm_money)[0]}}</label>,<label style="font-size: .8rem;">{{formatMoney(comm_data.users_comm_money)[1]}}</label>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-4"><p>Comisiones Pendientes</p></div>
                        <div class="col-sm-8">
                            ${{formatMoney(comm_data.pending_coms_money)[0]}},<label style="font-size: .8rem;">{{formatMoney(comm_data.pending_coms_money)[1]}}</label>
                        </div>
                    </div>
                    <!--------------------------------------------->
                    <div v-if="session_user.pages.payment"
                    class="row mt-1" style="text-decoration: underline; cursor: pointer" @click="showPayments()">
                        <div class="col-sm-4"><p>Comisiones Pagadas</p></div>
                        <div class="col-sm-8">
                            <label>${{formatMoney(comm_data.total_payments_money)[0]}}</label>,<label style="font-size: .8rem;">{{formatMoney(comm_data.total_payments_money)[1]}}</label>
                        </div>
                    </div>
                    <div class="row mt-1" v-else>
                        <div class="col-sm-4"><p>Comisiones Pagadas</p></div>
                        <div class="col-sm-8">
                            <label>${{formatMoney(comm_data.total_payments_money)[0]}}</label>,<label style="font-size: .8rem;">{{formatMoney(comm_data.total_payments_money)[1]}}</label>
                        </div>
                    </div>
                    <!--------------------------------------------->
                    <div class="row mt-1" style="text-decoration: underline; cursor: pointer" @click="showCompanyCom()">
                        <div class="col-sm-4"><p>Comisiones Empresa</p></div>
                        <div class="col-sm-8">
                            <label>${{formatMoney(comm_data.company_comm_money)[0]}}</label>,<label style="font-size: .8rem;">{{formatMoney(comm_data.company_comm_money)[1]}}</label>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div style="display: flex; justify-content: center; ">
                        <div class="lds-dual-ring"></div>
                    </div>
                </div>
            </div>
            <div class="info-card">
                <p>Lista de Usuarios</p>
                <hr>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <small>Tipo de búsqueda</small>
                        <select name="" v-model="type_search" class="form-control">
                            <option value="code">Código</option>
                            <option value="card">Documento</option>
                            <option value="name">Nombre</option>
                            <option value="email">Correo</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="justify-content: space-between;">
                    <div class="col-md-4">
                        <p style="display: inline-block;">Mostrar</p>
                        <select 
                            class="select" 
                            v-model="limit_commissions"
                            @change="loadCommissionsUsers()"
                        >
                            <option value="10">10</option>
                            <option value="100">100</option>
                            <option value="1000000">Todos</option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Búsqueda..." v-model="search">
                            <div class="input-group-append">
                                <button type="submit" class="input-group-text"><span class="material-icons-sharp">search</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="table" v-if="loaded_comm">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Documento</th>
                            <th>Comisión</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(commission, index) in commissions" :key="index">
                            <td>{{commission.user_id || '-'}}</td>
                            <td>{{commission.user_name || 'Multinivel Café'}}</td>
                            <td>{{commission.id_card || '---'}}</td>
                            <td>${{formatMoney(commission.money)[0]}},<label style="font-size: .8rem;">{{formatMoney(commission.money)[1]}}</label></td>
                            <td>
                                <router-link :to="`/cartera/${commission.user_id}`" class="btn btn-secondary" 
                                style="display: inline-flex; align-items: center;">
                                    <span class="material-icons-sharp active" style="font-size: 1.2rem;">wallet</span>
                                </router-link>
                                <a v-if="session_user.pages.commision_payment"
                                @click="showModalPayment(commission)" class="btn btn-danger" 
                                style="display: inline-flex; align-items: center;">
                                    <span class="material-icons-sharp active" style="font-size: 1.2rem;">point_of_sale</span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <div style="display: flex; justify-content: center; ">
                        <div class="lds-dual-ring"></div>
                    </div>
                </div>
            </div>
        </main>
        <Right/>
        <!--------------MODAL----------------->
        <div class="modal fade" id="modal-company-com" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">
                            COMISIONES DE EMPRESA
                        </h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped" id="table-company-commissions" style="border:none">
                            <thead>
                                <tr>
                                    <th>Punto Venta</th>
                                    <th>Cliente</th>
                                    <th>Ganancia</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!------------MODAL COMS------------>
        <div class="modal fade" id="modal-payment" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title"><p>Cancelar Comisión</p></h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-4"><p>Código</p></div>
                            <div class="col-8">{{commission.user_id}}</div>
                            <div class="col-4"><p>Nombre</p></div>
                            <div class="col-8">{{commission.user_name}}</div>
                            <div class="col-4"><p>Dinero</p></div>
                            <div class="col-8">${{formatMoney(commission.money)[0]}},<label style="font-size: .8rem;">{{formatMoney(commission.money)[1]}}</label></div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-8">
                                <input type="number" class="form-control" v-model="payment_money" placeholder="$">
                            </div>
                            <div class="col-4">
                                <button class="btn btn-danger" style="width:100%;" @click="payCommission()">Pagar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--------------MODAL PAGOS----------------->
        <div class="modal fade" id="modal-payments" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">
                            PAGOS REALIZADOS
                        </h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-striped" id="table-payments" style="border:none">
                            <thead>
                                <tr>
                                    <th>Documento</th>
                                    <th>Cliente</th>
                                    <th>Dinero</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';

export default {
    name: '',
    data: function (){
        return {
            search: '',
            type_search: 'code',
            payment_money: '',
            commission: {
                user_id: '',
                user_name: '',
                user_id_card:  '',
                money: ''
            },
            loaded_comm: false,

            commissions : [],
            limit_commissions: 10
        }
    },
    components: {
        Aside,
        Right,
    },
    methods: {
        payCommission(){
            if(this.payment_money > this.commission.money) return this.launchAlert({type: 'warning', title: 'El pago excede la cartera del usuario'});
            this.$swal.fire({
                title: '¿Estás seguro que deseas realizar este pago?',
                html: `Realizar pago <b>$${this.formatMoney(this.payment_money)[0]},<label style="font-size: .9rem;">${this.formatMoney(this.payment_money)[1]}</label></b> a 
                <b>${this.commission.user_name}</b>`,
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    let json = {user_id: this.commission.user_id, money: this.payment_money}
                    this.paymentCommission(json).then(res => {
                        if(res.status == 201){
                            this.infoCommissions();
                            this.loadCommissionsUsers();
                            this.payment_money = '';
                            $('#modal-payment').modal('hide');
                            return this.launchAlert({type: 'success', title: 'Comisión pagada exitosamente'})
                        } else return console.log(res)
                    }).catch(err => {console.log(err.response)});
                }
            })
        },
        loadCommissionsUsers(){
            this.searchCommissions({group_by: 'user_id', order: 'ASC', limit: this.limit_commissions})
            .then(res => {
                this.loaded_comm =  true;
                this.commissions = res.data;
            }).catch(err => console.log(err.response))
        },
        renderTableSearch(data){
            this.searchCommissions(data).then(res => {
                this.loaded_comm =  true;
                this.commissions = res.data;
            }).catch(err => console.log(err.response))
        },
        showModalPayment(user){
            this.commission.user_id = user.user_id;
            this.commission.user_name = user.user_name;
            this.commission.user_id_card = user.user_id_card;
            this.commission.money = user.money;
            $('#modal-payment').modal('show');
        },
        showPayments(){
            $('#modal-payments').modal('show');
            this.searchPayments().then(res => {
                let new_array = [];
                res.data.forEach(element => {
                    let commission = `$${this.formatMoney(element.money)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.money)[1]}</label>`;
                    let date = this.formatHourDate(element.created_at);
                    let json = {
                        user_id_card: element.id_card,
                        user_name: element.user_name,
                        commission: commission,
                        created_at: date
                    }
                    new_array.push(json);
                });
                $('#table-payments').DataTable({
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data: new_array,
                    columns: [
                        {"data": "user_id_card"},
                        {"data": "user_name"},
                        {"data": "commission"},
                        {"data": "created_at"},
                    ]
                });
            })
        },  
        showCompanyCom(){
            $('#modal-company-com').modal('show');
            this.searchCommissions({type: 'company'}).then(res => {
                let new_array = [];
                res.data.forEach(element => {
                    let commission = `$${this.formatMoney(element.money)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.money)[1]}</label>`;
                    let date = this.formatDate(element.created_at);
                    let json = {
                        sale_point_name: element.sale_point_name,
                        client_user_name: element.client_user_name,
                        commission: commission,
                        created_at: date
                    }
                    new_array.push(json);
                });
                $("#table-company-commissions").DataTable({
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data: new_array,
                    columns: [
                        {"data": "sale_point_name"},
                        {"data": "client_user_name"},
                        {"data": "commission"},
                        {"data": "created_at"},
                    ]
                });
            }).catch(err => console.log(err.response));
        },
        formatMoney(number){
            let money = new Intl.NumberFormat('em-IN').format(number);
            let split = money.split(',');
            let decimals = split[1];
            if(!decimals) decimals = '00';
            if (window.innerWidth <= 838) return [Math.trunc(number), '00']; 
            return [split[0], decimals];
        },
        formatHourDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            var horas = fecha.getHours();
            var minutos = fecha.getMinutes();

            if(mes < 10){
                mes="0"+mes
            }
            if(dia < 10 ){
                dia="0"+dia
            }
            return `${ano}-${mes}-${dia} ${horas}:${minutos}`;
        },
        formatDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            if(mes < 10)  mes = "0" + mes
            if(dia < 10) dia = "0" + dia
            return ano+"/"+mes+"/"+dia;
        },
        launchAlert(config){
            if(!config.timeout) config.timeout = 2500;
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: config.timeout,
            })
            Toast.fire({
                icon: config.type,
                title: config.title,
                text: config.message,
            })
        },
        ...mapActions("user", ["getUsersLine"]),
        ...mapMutations(['loadSessionUser']),
        ...mapMutations('commission', ['infoCommissions']),
        ...mapActions("commission", ["searchPayments"]),
        ...mapActions("commission", ["searchCommissions"]),
        ...mapActions("commission", ["paymentCommission"]),
    },
    mounted: function(){
        $('.modal-backdrop').remove();
        this.loadSessionUser();
        this.infoCommissions();
        let session_interval = setInterval(() => {
            if(this.session_user.id) {
                this.loadCommissionsUsers();
                return clearInterval(session_interval);
            }
        }, 100);
    },
    computed: {
        ...mapState(['session_user']),
        ...mapState('commission', ['comm_data']),
    },
    watch: {
        search: function () {
            this.renderTableSearch({
                search:this.search, 
                type_search: this.type_search, 
                group_by: 'user_id', 
                order: 'ASC', 
                limit: this.limit_commissions
            });
        }
    }
}
</script>
<style scoped>
    .select {
        width: auto;
        display: inline-block;
        margin-left: 0.5rem;
        padding: 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
</style>